<template>
  <div>
    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">Create User</div>
      </v-card-title>
      <v-card-text>
        <template>
          <v-form
            v-model="valid"
            ref="createform"
            @submit.prevent="handleSubmit(save)"
            lazy-validation
          >
            <v-text-field
              label="E-mail"
              type="email"
              v-model="email"
              required
              :rules="emailRules"
            />
            <v-select
              label="Country"
              v-model="country"
              :items="Country"
              required
              :rules="required"
            />
            <div class="subheading secondary--text text--lightn-2">
              User is superuser
              <span v-if="is_superuser">(currently is a superuser)</span>
              <span v-else>(currently is not a superuser)</span>
            </div>
            <v-checkbox
              label="Is Superuser"
              v-model="is_superuser"
            />
            <div class="subheading secondary--text text--lighten-2">
              User is active <span v-if="is_active">(currently active)</span>
              <span v-else>(currently not active)</span>
            </div>
            <v-checkbox
              label="Is Active"
              v-model="is_active"
            />
            <div class="subheading secondary--text text--lighten-2">
              User is verified <span v-if="is_verified">(currently verified)</span>
              <span v-else>(currently not verified)</span>
            </div>
            <v-checkbox
              label="Is Verified"
              v-model="is_verified"
            />
            <v-layout align-center>
              <v-flex>
                <v-text-field
                  type="password"
                  ref="password"
                  label="Set Password"
                  v-model="password1"
                  required
                  :rules="[rules.required, rules.minin, passwordMatchRule]"                  
                />
                <v-text-field
                  type="password"
                  label="Confirm Password"
                  v-model="password2"
                  required
                  :rules="[rules.required, rules.minin, passwordMatchRule]"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="cancel"
          color="error"
        >
          Cancel
        </v-btn>
        <v-btn
          @click="reset"
          color="biscuit"
        >
          Reset
        </v-btn>
        <v-btn
          @click="submit"
          :disabled="!valid"
          color="guacamole"
        >
          <span v-if="!loading">Save</span>
          <v-progress-circular v-else indeterminate color="biscuit" /> 
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { NewUser } from "@/models/users.js";
import { Country } from "@/utils/selectoptions.js";

export default {
  data() {
    return {
      valid: false,
      required: [
        v => !!v || 'Field is required'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      rules: {
        required: value => !!value || 'Field is required.',
        // min: value => value.length >= 8 || 'Min 8 characters'
        minin: v => (v && v.length >= 8) || 'Min 8 characters'
      },
      // fullName: "",
      email: "",
      country: "",
      is_active: true,
      is_superuser: false,
      is_verified: false,
      setPassword: false,
      password1: "",
      password2: "",
      Country: Country
    };
  },
  mounted() {
    this.reset();
    // this.valid = false
  },
  methods: {
    reset() {
      this.password1 = "";
      this.password2 = "";
      // this.fullName = "";
      this.email = "";
      this.country = "";
      //this.is_active = true;  // should be true, place after reset
      this.is_superuser = false;
      this.is_verified = false;
      this.valid=false;
      this.$refs.createform.reset();
      this.is_active = true;
    },
    cancel() {
      this.$router.back();
    },
    async submit() {
      this.$refs.createform.validate()
      const newuser = new NewUser()
      newuser.email = this.email
      
      if (this.country)
        newuser.country = this.country
      
      if (this.is_active)
        newuser.is_active = this.is_active
      
      if (this.is_superuser)
        newuser.is_superuser = this.is_superuser

      if (this.is_verified)
        newuser.is_verified = this.is_verified

      newuser.password = this.password1

      try {
        this.$store.dispatch('adminusers/createUser', JSON.stringify(newuser))
          .then(this.$router.push('/dashboard/users/'))
      } catch (error) {
        console.log(error)
      }
    },
  },
  computed: {
    ...sync('adminusers', [
      'loading'
    ]),
    passwordMatchRule() {
      return this.password1 === this.password2 || 'Passwords must match'
    }
  }
};
</script>